import "./index.css"

import { graphql } from "gatsby"
import React from "react"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PatternLateral from "../../components/patternLateral/PatternLateral"

export default function IndexPage() {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={t("Aviso legal")} />

      <PatternLateral />
      <h2 className="sectionTitle">
        <Trans>Aviso legal</Trans>
      </h2>

      <div className="mainText legalBody" id="formTop">
        Le agradecemos que haya decidido visitarnos. Queremos que su experiencia
        en nuestra Web sea lo mejor posible. El acceso y la navegación en el
        sitio web, o el uso de los servicios del mismo, implican la aceptación
        expresa e íntegra de todas y cada una de las presentes Condiciones
        Generales, incluidas tanto las Condiciones Particulares fijadas para
        ciertas promociones, así como de las Políticas de Privacidad y de las
        Cookies, relacionadas con la normativa vigente en la materia de
        Protección de Datos Personales. <br />
        <br />
        Por favor, le recomendamos que las lea con atención para saber qué tipo
        de datos se recogen a través de nuestra Web, con qué finalidades y qué
        derechos relacionados con ello le conciernen.
        <h3 className="basic">1. Información Legal.</h3>
        En cumplimiento de la Ley 34/2002, de 11 de julio, de Servicios de la
        Sociedad de la Información y del Comercio Electrónico, los datos
        identificativos del titular del Portal Web son:
        <br />
        Identidad: <b> La Tribu Animation, S.L.</b>
        <br />
        CIF: <b>B98763113</b>
        <br />
        Dirección postal:{" "}
        <b>Avenida Doctor Peset Aleixandre 58 1B 46025 – Valencia (VALENCIA)</b>
        <br />
        Teléfono: <b>960883879</b>
        <br />
        Correo-e: <b>contact@latribu.es</b>
        <br />
        Contacto DPD.: (en su caso)
        <br />
        URL:<b> www.latribu.es</b>
        <br />
        <br />
        El acceso a la Web supone la aceptación expresa del Usuario a las
        presentes Condiciones Generales de Uso, que podrán ser modificadas total
        o parcialmente o sustituidas por otras, por su titular y en cualquier
        momento sin previo aviso. Le recomendamos que acceda a las presentes
        condiciones de vez en cuando para comprobar sus posibles modificaciones,
        que se publicarán en el mismo apartado.
        <h3 className="basic">2.- Condiciones Generales de Uso.</h3>
        Las siguientes Condiciones Generales regulan el uso y acceso al portal
        Web, cuya finalidad es establecer pautas de buen uso y comportamiento de
        la misma. La Tribu Animation, S.L., pone a disposición de los usuarios
        información sobre el uso, los servicios y los contenidos que se alojan
        en la Web. A través ésta, el Usuario puede tener acceso a información
        sobre productos y servicios específicos, herramientas y aplicaciones.
        Los datos e informaciones sobre productos o servicios, precios y
        características u otros datos relevantes ofrecidos a través de la página
        web son realizados, proporcionados y actualizados por Proveedores y
        terceras empresas.
        <br />
        <br />
        La Tribu Animation, S.L. no es, en ningún caso, responsable de dichos
        datos e informaciones ni asume ninguna obligación sobre los mismos. El
        Usuario se compromete a hacer un uso adecuado de los contenidos,
        servicios, aplicaciones y herramientas que están accesibles, con
        sujeción a la Ley y a las presentes Condiciones Generales de Uso y, en
        su caso a las Condiciones Particulares que puedan establecerse para el
        posible acceso a determinados servicios y aplicaciones, respetando en
        todo momento a los demás Usuarios de la misma.
        <br />
        <br />
        En caso de incumplimiento total y/o parcial por el Usuario de las
        presentes condiciones Generales de Uso, La Tribu Animation, S.L. se
        reserva el derecho de denegar el acceso a la Web, sin necesidad de
        previo aviso al Usuario.
        <h3 className="basic">3. Obligaciones Generales del Usuario.</h3>
        El Usuario, al aceptar las presentes condiciones Generales de Uso, se
        obliga expresamente a:
        <ul>
          <li>
            No realizar ninguna acción destinada perjudicar, bloquear, dañar,
            inutilizar, sobrecargar, de forma temporal o definitiva, las
            funcionalidades, herramientas, contenidos y/o la infraestructura de
            la página web, de manera que impida su normal utilización.
          </li>
          <li>
            Custodiar y mantener la confidencialidad de las claves de acceso
            asociadas a su nombre de Usuario, siendo responsable de la
            utilización de dichas claves de acceso personales e intransferibles
            por parte de terceros.
          </li>
          <li>
            No introducir ni realizar contenidos injuriosos o calumniosos, tanto
            de otros usuarios como de terceras empresas ajenas a La Tribu
            Animation, S.L..
          </li>
          <li>
            No utilizar cualquiera de los materiales e informaciones contenidos
            en este Sitio Web con fines ilícitos y/o expresamente prohibidos en
            las presentes Condiciones Generales de Uso, así como a las
            condiciones particulares que, en su caso, se establezcan para
            determinadas aplicaciones y/o utilidades y que resulten contrarios a
            los derechos e intereses de La Tribu Animation, S.L., sus usuarios
            y/o terceros.
          </li>
          <li>
            No ofertar ni distribuir productos y servicios, ni realizar
            publicidad o comunicaciones comerciales no solicitadas a otros
            Usuarios y visitantes de La Tribu Animation, S.L.
          </li>
        </ul>
        El Usuario responderá de los todos los daños y perjuicios de toda
        naturaleza que La Tribu Animation, S.L. o cualquier tercero pueda sufrir
        como consecuencia delincumplimiento de cualesquiera de las obligaciones
        a las que queda sometido por virtud de estas “Condiciones Generales de
        Uso” o de la ley en relación con el acceso y/o utilización de la página.
        <h3 className="basic">4. Propiedad Intelectual e Industrial.</h3>
        El sitio web, las páginas que comprende y la información o elementos
        contenidos en las mismas (incluyendo textos, documentos, fotografías,
        dibujos, representaciones gráficas, de los que es titular o de los que
        sean titulares terceros que autorizan al propietario a su
        utilización,entre otros), así como logotipos, marcas, nombres
        comerciales u otros signos distintivos, se encuentran protegidos por
        derechos de propiedad intelectual y/o industrial, de los que La Tribu
        Animation, S.L. es titular o ostenta autorización para su utilización y
        comunicación pública de los legítimos titulares de las mismas.
        <br />
        <br />
        El Usuario se obliga a usar los contenidos de forma diligente y
        correcta, de acuerdo a la ley, la moral y el orden público. La Tribu
        Animation, S.L. autoriza al Usuario para visualizar la información que
        se contiene en este sitio web, así como para efectuar reproducciones
        privadas (simple actividad de descarga y almacenamiento en sus sistemas
        informáticos), siempre y cuando los elementos sean destinados únicamente
        al uso personal. En ningún caso, ello significará una autorización o
        licencia sobre los derechos de propiedad de La Tribu Animation, S.L. o
        de los legítimos titulares de las mismas. El Usuario no está autorizado
        para proceder a la distribución, modificación, cesión o comunicación
        pública de la información contenida en esta Web en cualquier forma y
        cualquiera que sea su finalidad.
        <h3 className="basic">5. Enlaces.</h3>
        Las conexiones y enlaces a sitios o páginas Web de terceros se han
        establecido únicamente como una utilidad para el Usuario. La Tribu
        Animation, S.L. no es, en ningún caso, responsable de las mismas o de su
        contenido. La Tribu Animation, S.L. no asume ninguna responsabilidad La
        derivada de la existencia de enlaces entre los contenidos de este sitio
        y contenidos situados fuera del mismo o de cualquier otra mención de
        contenidos externos a este sitio. Tales enlaces o menciones tienen una
        finalidad exclusivamente informativa y, en ningún caso, implican el
        apoyo, aprobación, comercialización o relación alguna entre La Tribu
        Animation, S.L. y las personas o entidades autoras y/o gestoras de tales
        contenidos o titulares de los sitios donde se encuentren. Para realizar
        enlaces con la página Web será necesaria la autorización expresa y por
        escrito de los titulares del portal.
        <h3 className="basic">6. Responsabilidad.</h3>
        La Tribu Animation, S.L. no garantiza el acceso continuado, ni la
        correcta visualización, descarga o utilidad de los elementos e
        información contenidos en las páginas del portal que pueden verse
        impedidos, dificultados o interrumpidos por factores o circunstancias
        fuera de su control o ajenas a su voluntad, ni de aquellos que sean
        producidos por la existencia de virus informáticos en Internet. La Tribu
        Animation, S.L. no asume responsabilidad alguna por daños, perjuicios,
        pérdidas, reclamaciones o gastos, producidos por:
        <ul>
          <li>
            (i) Interferencias, interrupciones, fallos, omisiones, retrasos,
            bloqueos o desconexiones, motivadas por errores en las líneas y
            redes de telecomunicaciones o por cualquier otra causa ajena al
            control de La Tribu Animation, S.L.;
          </li>
          <li>
            (ii) Intromisiones ilegítimas mediante el uso de programas malignos
            de cualquier tipo y a través de cualquier medio de comunicación,
            tales como virus informáticos o cualesquiera otros;
          </li>
          <li>
            (iii) Uso indebido o inadecuado de la página web de La Tribu
            Animation, S.L.;
          </li>
          <li>
            (iv) Errores de seguridad o navegación producidos por un mal
            funcionamiento del navegador o por el uso de versiones no
            actualizadas.
          </li>
        </ul>
        La Tribu Animation, S.L. no es responsable ni en ningún caso responderá
        frente a los usuarios y a terceros sobre actos de cualquier tercero
        ajeno a La Tribu Animation, S.L. que conlleve o pueda suponer la
        realización de actos de competencia desleal y publicidad ilícita o la
        infracción de los derechos de propiedad intelectual e industrial, de los
        secretos empresariales, de compromisos contractuales de cualquier clase,
        de los derechos al honor, a la intimidad personal y familiar y a la
        imagen, de los derechos de propiedad y de toda otra naturaleza
        pertenecientes a un tercero por razón de la transmisión, difusión,
        almacenamiento, puesta a disposición, recepción, obtención o acceso a
        los contenidos.
        <h3 className="basic">7. Protección de Datos de Carácter Personal.</h3>
        Toda la política de tratamiento de datos personales la encontrará en la
        Política de Privacidad, que forma parte integrante de estas Condiciones
        Generales pero que, por simplicidad, hemos colocado en un documento
        aparte: Política de Privacidad.
        <h3 className="basic">8. Legislación.</h3>
        El presente Aviso Legal y sus términos y condiciones se regirán e
        interpretarán de acuerdo con la Legislación Española. El usuario, por el
        solo hecho de acceder a la página web u obtener la condición de usuario
        registrado otorga, de forma irrevocable, su consentimiento a que los
        Tribunales competentes por defecto puedan conocer de cualquier acción
        judicial derivada de o relacionada con las presentes condiciones, o con
        su uso de este Sitio o la navegación realizada por el mismo. Si alguna
        cláusula o apartado de las presentes Condiciones Generales, que no sea
        de naturaleza esencial para la existencia del mismo, es declarada nula o
        inaplicable, la validez de las restantes cláusulas no se verá afectada.
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
